/// <reference types="@sveltejs/kit" />
import { dev } from "$app/environment";
import { goto } from "$app/navigation";
import { page } from "$app/stores";
import { AdminAccount } from "$hewsync/definitions/admin/Account";
import { AdminGroup } from "$hewsync/definitions/admin/Group";
import { AdminRole } from "$hewsync/definitions/admin/Role";
import { ProjectInstance } from "$hewsync/definitions/project/Instance";
import { SynapseFile } from "$hewsync/definitions/synapse/File";
import { UserAccount } from "$hewsync/definitions/user/Account";
import { UserAsset } from "$hewsync/definitions/user/Asset";
import { UserGroup } from "$hewsync/definitions/user/Group";
import { UserMember } from "$hewsync/definitions/user/Member";
import { UserOrganization } from "$hewsync/definitions/user/Organization";
import { UserRole } from "$hewsync/definitions/user/Role";
import { HewSync } from "$hewsync/HewSync";
import type { HewSyncAttachment } from "$hewsync/HewSyncAttachmentList";
import { HewSyncType } from "$hewsync/HewSyncType";
import {
	AdminAccountID,
	AdminGroupID,
	AdminRoleID,
	HewSyncPath,
	ProjectInstanceID,
	UserAccountID,
	UserEntityID,
	UserGroupID,
	UserOrganizationID,
	UserRoleID
} from "$hewsync/IDs";
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { BasicEvent } from "@h4x/common";
import { addToast as internalAddToast, staticFloat, ToastUtils, type Type as Variants } from "@hewmen-internal/heui";
import type { ButtonProps } from "@hewmen-internal/heui/components/Button/Button.svelte";
import { createEventDispatcher, getContext } from "svelte";
import { get, writable, type Readable, type Subscriber, type Unsubscriber, type Writable } from "svelte/store";
import ErrorReport from "./components/ErrorReport.svelte";
import { UserEntity } from "$hewsync/definitions/user/Entity";
import SentryExceptionError from "./components/error/SentryExceptionError.svelte";

// import { PUBLIC_STAGE, PUBLIC_RUN_MODE } from "$env/static/public";

export const getOrOverrideToken = (jwt: string) => {
	let value = localStorage.getItem("HEWMEN_TOKEN_OVERRIDE_VALUE");
	const tokenOverrideValue = value ? JSON.parse(value || "") : null;
	if (inDebugMode() && tokenOverrideValue !== null && tokenOverrideValue !== "") {
		console.warn("[WARNING] Using provided override token:", tokenOverrideValue);
		return tokenOverrideValue;
	}

	console.debug("[getOrOverrideToken] Using provided token:", jwt);
	return jwt;
};
export class ObservableSet<T> {
	public onUpdate = new BasicEvent<() => void>();

	private set = new Set<T>();

	get items() {
		return this.set;
	}

	public add(value: T) {
		this.set.add(value);
		this.onUpdate.execute();
		return this;
	}

	public delete(value: T) {
		let ret = this.set.delete(value);
		this.onUpdate.execute();
		return ret;
	}

	public clear() {
		this.set.clear();
		this.onUpdate.execute();
	}

	public subscribe(run: Subscriber<Set<T>>): Unsubscriber {
		let ref = this.onUpdate.addCallback(() => {
			run(this.set);
		});

		run(this.set);

		return () => {
			ref.remove();
		};
	}

	public reorder(value: T) {
		this.delete(value);
		this.set = new Set([value, ...this.set]);
		return this;
	}
}

export type TabbedPageContext = {
	actionButtons?: (ButtonProps & { label?: string } & ({ dispatchModalEvent?: string } | { onClick: () => void }))[];
	filter?: Record<string, unknown>[];
	itemCount?: Writable<number>;
	loadable?: boolean;
	loading?: boolean;
	searchString?: string;
	selections?: ObservableSet<any>;
	supportsItemCount?: boolean;
	supportsSearch?: boolean;
	supportsViewToggle?: boolean;
	viewType?: Writable<"card" | "list">;
};

const DEFAULT_CONTEXT: TabbedPageContext = {
	actionButtons: [],
	itemCount: writable(0),
	loadable: true,
	loading: false,
	searchString: "",
	selections: new ObservableSet<any>(),
	supportsItemCount: true,
	supportsSearch: true,
	supportsViewToggle: true
};

type StripWritable<Type> = Type extends Writable<infer U> ? StripWritable<U> : Type;

export type UpdateTabbedPageContext = {
	[Key in keyof TabbedPageContext]: StripWritable<TabbedPageContext[Key]>;
};

type StripSetPrefix<S extends string> = S extends `set${infer P1}${infer P2}` ? `${Lowercase<P1>}${P2}` : S;
export type UpdateTabbedPageContextFunctions = {
	[Key in `set${Capitalize<keyof TabbedPageContext>}`]: (
		value: Exclude<StripWritable<TabbedPageContext[StripSetPrefix<Key>]>, null | undefined>
	) => void;
};

export type TabbedPageFullContext = TabbedPageContext & UpdateTabbedPageContextFunctions;

export const getPageContext = <
	FullType extends boolean = false,
	Return = FullType extends true ? TabbedPageFullContext : TabbedPageContext
>(): Return => {
	return getContext<Return>("TabbedPage");
};

export type HewSyncDataStore = {
	authorized: boolean;
	initialLoad: () => Promise<boolean>;
	fetchAll: () => Promise<void>;
	fetchMore: () => Promise<boolean>;
	errors?: Error[];
	items: any[];
	loading: boolean;
	updating: boolean;
};

export const updateTabbedPageContext = (values?: UpdateTabbedPageContext, dataStore?: Readable<HewSyncDataStore>) => {
	if (!values) {
		return;
	}

	let context = getContext<TabbedPageFullContext>("TabbedPage");
	values = { ...DEFAULT_CONTEXT, ...values } as UpdateTabbedPageContext;

	if (dataStore) {
		const storeValue = get(dataStore);
		values = { ...values, ...{ itemCount: storeValue.items.length, loading: storeValue.loading } };
	}

	// eslint-disable-next-line guard-for-in
	for (const key in values) {
		const functionName = `set${key.slice(0, 1).toUpperCase() + key.slice(1)}` as keyof UpdateTabbedPageContextFunctions;
		if (typeof context?.[functionName] !== "function") {
			console.error(`[TabbedPageContext.updateTabbedPageContext] Update method \"${functionName}\" not found`);
			return;
		}

		context[functionName](
			// @ts-expect-error The typings on this are complex, not sure how to coalesce them to the proper type
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			values[key]
		);
	}
};

export const useLocalStorage = <Type>(key: string, defaultValue?: Type): Writable<Type> => {
	if (typeof window === "undefined") {
		return writable(defaultValue);
	}

	const value = writable(defaultValue);
	let localValue = localStorage.getItem(key);
	if (localValue) {
		value.set(JSON.parse(localValue) as Type);
	}

	value.subscribe((latestValue) => {
		if (localValue === latestValue) {
			return;
		}

		if (latestValue === undefined) {
			console.warn(`[useLocalStorage]: Skipping over undefined value - ${key}:${JSON.stringify(latestValue)}`);
			return;
		}

		localStorage.setItem(key, JSON.stringify(latestValue));
	});

	window.addEventListener("storage", () => {
		const storedValue = localStorage.getItem(key);
		if (storedValue == null) {
			return;
		}

		try {
			localValue = JSON.parse(storedValue) as string;
		} catch {
			console.error(`[useLocalStorage]: Failed to parse value - key: ${key}, value: ${storedValue}`);
			return;
		}
		if (localValue !== get(value)) {
			value.set(localValue as Type);
		}
	});

	return value;
};

const DEBUG_LOCAL_STORAGE_KEY = "HEWMEN_DEBUG";

export const debugMode = useLocalStorage(DEBUG_LOCAL_STORAGE_KEY, "false");
export const inDebugMode = () => {
	return get(debugMode) !== "false";
};

export const disableDebugMode = () => {
	localStorage.removeItem(DEBUG_LOCAL_STORAGE_KEY);
	window.location.reload();
};

export const enableDebugMode = () => {
	localStorage.setItem(DEBUG_LOCAL_STORAGE_KEY, "true");
	window.location.reload();
};

export const DEFAULT_VARIANTS: Record<Variants, string[]> = {
	default: ["bg-app-wrapper-nav-item-background"],
	error: ["bg-message-error-background"],
	fourth: ["bg-app-wrapper-background"],
	info: ["bg-message-info-background"],
	plain: ["bg-transparent"],
	primary: [],
	secondary: ["bg-card-secondary-background"],
	success: ["bg-message-success-background"],
	warn: ["bg-message-warn-background"]
};

const CAPITALIZED_DATA_KEYS = ["id", "pk", "sk"];

export const mapKeyToLabel = (key: string) => {
	if (key === "createdAt") {
		return "Creation Date";
	} else if (CAPITALIZED_DATA_KEYS.includes(key)) {
		return key.toUpperCase();
	} else {
		let value = key.replace(/[A-Z]/g, (txt) => {
			return ` ${txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()}`;
		});
		return value.slice(0, 1).toUpperCase() + value.slice(1);
	}
};

export const navigateTo = (currentPage?: null | string, to?: null | string) => {
	if (dev) {
		console.debug(`[${currentPage}] Navigating to: ${to}...`);
	}

	goto(to).catch((error) => {
		console.error(`[${currentPage}] Unable to navigate to ${to}:`, error);
	});
};

export type ViewType = "card" | "list";

export type SelectionRecord = { id: string; name: string };
export type SelectionRecordFull = SelectionRecord & { value: boolean };

export type Route = {
	children?: Record<string, Route>;
	exact?: boolean;
	icon?: IconDefinition;
	image?: string;
	label: string;
	showInNav?: boolean;
	kind?: string;
	videoIcon?: string;
	visual?: boolean;
	root?: boolean;
};

export const handleSelection = (selections: ObservableSet<any>, { detail }: CustomEvent<SelectionRecordFull>) => {
	if (selections.items.size > 25) {
		addToast({ message: "More than 25 items cannot be selected", type: "warn" });
		return;
	}

	let item = detail.item as HewSyncType | HewSyncAttachment<HewSyncType, HewSyncType>;

	if (detail.value) {
		if (!selections.items.has(item)) {
			selections.add(item);
			item.onRemove.addCallback(selections.delete, selections);
		}
	} else {
		if (selections.items.has(item)) {
			selections.delete(item);
			item.onRemove.removeCallback(selections.delete, selections);
		}
	}
};

const showError = (event: ErrorEvent | PromiseRejectionEvent) => {
	const store = writable("1");

	staticFloat({
		left: 0,
		top: 0,
		component: SentryExceptionError,
		props: {
			event,
			store
		},
		store
	});
};

export const registerErrorHandlers = () => {
	window.addEventListener("error", (event) => {
		console.error("[onError]", event);
		showError(event);
	});

	window.addEventListener("unhandledrejection", (event) => {
		console.error("[onUnhandledRejection]", event);
		showError(event);
	});
};

export const addDatasetProp = (node: HTMLElement, id: string) => {
	if (node?.dataset) {
		node.dataset.autohide = id;
		const children = node?.children;

		for (let i = 0; i < children.length; i++) {
			const el = children[i] as HTMLElement;
			el.dataset.autohide = id;
			addDatasetProp(el, id);
		}
	}

	return {
		destroy() {}
	};
};

export type OperationType = "UPLOAD" | "MOVE" | "RENAME" | "COPY" | "DELETE";

export const OPERATION_STATES = ["UPLOAD", "MOVE", "RENAME", "COPY", "DELETE"];

export type OperationStatus = OperationType | "PENDING" | "COMPLETE" | "ERROR";

export type OperationDetails = {
	items: File[] | SynapseFile[];
	operation: OperationType;
	name?: string;
	directory?: string[];
	APP_ORGANIZATION?: string;
	APP_INSTANCE?: string;
	kind?: string;
	UploadClass?: typeof SynapseFile | typeof UserAsset;
};

export type ProcessFileTypeWritable = {
	pendingFiles: ProcessFile[];
	currentlyProcessing: { item: ProcessFile; promise: Promise<any> }[];
	finishedFiles: Map<string, ProcessFile>;
	allFiles: Map<string, ProcessFile>;
	started: boolean;
};

export class ProcessFile {
	public executeOperation: () => Promise<void | SynapseFile[] | SynapseFile>;
	public uuid = crypto.randomUUID();
	public file:
		| File
		| SynapseFile; /* Note: if uploading file, we need a [File], any other operation requires a [SynapseFile]*/
	public status: OperationStatus = "PENDING";
	public operation: OperationType;
	private details: OperationDetails;
	private UploadClass: typeof SynapseFile | typeof UserAsset = undefined;

	constructor(file: File | SynapseFile, details: OperationDetails) {
		console.debug("[ProcessFile] Processing file", { file, details });
		if (!file || !details) {
			console.error("[ProcessFile] Missing required parameters: file, details");
			return null;
		}

		if (details.operation === "UPLOAD" && file instanceof SynapseFile) {
			console.error("[File] needed for uploading files.");
			return null;
		}

		if (details.operation !== "UPLOAD" && file instanceof File) {
			console.error(`[SynapseFile] needed for ${details.operation} operation`);
			return null;
		}

		this.file = file;
		this.operation = details.operation;
		this.details = details;
		this.executeOperation = this.getExecutionFunction(details.operation);
		this.UploadClass = details.UploadClass;
	}

	_internalUpload(): Promise<void> {
		const directoryPath: string[] = this.details.directory;
		let res: SynapseFile | UserAsset;
		const file = this.file as File;
		const kind = this.details.kind;

		return new Promise<void>(async (resolve, reject) => {
			try {
				if (this.UploadClass.type === "SynapseFile") {
					res = await SynapseFile.create({
						fileType: file.type,
						organization: new UserOrganizationID(this.details.APP_ORGANIZATION),
						instance: new ProjectInstanceID(this.details.APP_INSTANCE),
						name: file.name,
						path: new HewSyncPath(directoryPath)
					}).execute();
				} else if (this.UploadClass.type === "UserAsset") {
					res = await UserAsset.create({
						fileType: kind,
						kind,
						organization: new UserOrganizationID(this.details.APP_ORGANIZATION)
					}).execute();
				} else {
					throw new Error("Invalid UploadClass");
				}

				let upload = await res.upload({ contentType: file.type }).execute();

				if (res instanceof SynapseFile) {
					await fetch(upload, {
						body: new Blob([file], { type: file.type }),
						method: "PUT",
						headers: { "x-amz-meta-path": [res.organization, res.instance, ...res.path.value, res.name].join("/") }
					});
				} else if (res instanceof UserAsset) {
					await fetch(upload, { body: new Blob([file], { type: file.type }), method: "PUT" });
					await res.update({ status: "done" }).execute();
				} else {
					throw new Error("Invalid UploadClass");
				}

				resolve();
			} catch (error) {
				console.error("[ProcessFile] Error uploading file", error);
				reject();
			}
		});
	}

	private _internalMove() {
		const file = this.file as SynapseFile;
		const path = this.details.directory;
		return file.updateKey({ path, name: file.name }).execute();
	}

	private _internalCopy() {
		const file = this.file as SynapseFile;
		const path = this.details.directory;
		return file.duplicate({ path: path, name: file.name }).execute();
	}

	private _internalRename() {
		const file = this.file as SynapseFile;
		const name = this.details.name;
		return file.updateKey({ path: file.path.value, name }).execute();
	}

	private _internalDelete() {
		const file = this.file as SynapseFile;
		return file.remove().execute();
	}

	upload() {
		console.debug("[ProcessFile::upload] uploaded started");
		return this._internalUpload;
	}

	move() {
		console.debug("[ProcessFile::move] moving started:", this.details);
		return this._internalMove;
	}

	copy() {
		console.debug("[ProcessFile::copy] Copy started:", this.details);
		return this._internalCopy;
	}

	rename() {
		console.debug("[ProcessFile::rename] Rename started", this.details);
		return this._internalRename;
	}

	delete() {
		console.debug("[ProcessFile::delete] Delete started:", this.details);
		return this._internalDelete;
	}

	private getExecutionFunction(operation: OperationType) {
		switch (operation) {
			case "UPLOAD":
				return this.upload();

			case "MOVE":
				return this.move();

			case "RENAME":
				return this.rename();

			case "COPY":
				return this.copy();

			case "DELETE":
				return this.delete();

			default:
				console.error("[ProcessFile::getExecutionFunction] Invalid operation type");
				break;
		}
	}
}

export function createQueueSystem(initialQueueLimit = 10) {
	const internalStore = writable<ProcessFileTypeWritable>({
		pendingFiles: [],
		currentlyProcessing: [],
		finishedFiles: new Map(),
		allFiles: new Map(),
		started: false
	});
	const { subscribe, set, update } = internalStore;

	let queueLimit = initialQueueLimit;
	let currentBatchPromise: Promise<any>;
	const finishedBatchEvent = new CustomEvent("finishedBatch");

	function queueItem(file: ProcessFile) {
		if (!file) {
			console.error("[createQueueSystem] Invalid file: There may have been a problem parsing the file. ");
			return;
		}
		update((currentStore) => {
			currentStore.allFiles.set(file.uuid, file);
			return { ...currentStore, pendingFiles: [...currentStore.pendingFiles, file] };
		});
	}

	function updateQueueLimit(newLimit: number) {
		queueLimit = newLimit;
	}

	function removeItemFromQueue(key: string) {
		internalStore.update((currentStore) => {
			const isItemCurrentlyProcessing = currentStore.currentlyProcessing.some((item) => item.item.uuid === key);
			if (isItemCurrentlyProcessing) {
				console.warn(`[removeItemFromQueue] Item is currently processing and cannot be removed from queue`);
				return;
			}

			currentStore.pendingFiles.filter((item) => item.uuid !== key);

			if (!currentStore.finishedFiles.delete(key)) {
				console.error("[removeItemFromQueue] Failed to remove item from [finishedFiles]");
			}

			if (!currentStore.allFiles.delete(key)) {
				console.error("[removeItemFromQueue] Failed to remove item from [allFiles]");
			}

			return { ...currentStore };
		});
	}

	function startQueue() {
		if (!get(internalStore).started) {
			update((currentStore) => {
				currentStore.started = true;
				return { ...currentStore };
			});

			batchFiles();
		}

		function batchFiles() {
			if (get(internalStore).pendingFiles.length > 0 && get(internalStore).currentlyProcessing.length < queueLimit) {
				const nextItem = get(internalStore).pendingFiles.shift();
				nextItem.status = nextItem.operation;

				const nextPromise = nextItem
					.executeOperation()
					.then((file) => {
						nextItem.status = "COMPLETE";

						update((currentStore) => {
							currentStore.allFiles.set(nextItem.uuid, nextItem);

							if (nextItem.operation === "RENAME") {
								nextItem.file = { ...nextItem.file, ...file[1] };
								currentStore.allFiles.set(nextItem.uuid, nextItem);
							}

							return { ...currentStore };
						});
					})
					.catch((error) => {
						nextItem.status = "ERROR";
						console.error(`[batchFiles] Error with ${nextItem.operation} operation: `, error);

						switch (nextItem.operation) {
							case "UPLOAD":
								addToast({ message: "File could not be uploaded.", type: "error" });
								break;
							case "MOVE":
								addToast({ message: "File could not be moved.", type: "error" });
								break;
							case "RENAME":
								addToast({ message: "File could not be renamed.", type: "error" });
								break;
							case "COPY":
								addToast({ message: "File could not be copied.", type: "error" });
								break;
							case "DELETE":
								addToast({ message: "File could not be deleted.", type: "error" });
								break;
						}

						update((currentStore) => {
							currentStore.allFiles.set(nextItem.uuid, nextItem);
							currentStore.finishedFiles.set(nextItem.uuid, nextItem);
							return { ...currentStore };
						});
					});
				update((currentStore) => {
					currentStore.allFiles.set(nextItem.uuid, nextItem);
					currentStore.currentlyProcessing = [
						...currentStore.currentlyProcessing,
						{ item: nextItem, promise: nextPromise }
					];

					return {
						...currentStore,
						pendingFiles: currentStore.pendingFiles.filter((item) => item.uuid !== nextItem.uuid)
					};
				});

				batchFiles();
			} else if (
				(get(internalStore).pendingFiles.length > 0 || get(internalStore).currentlyProcessing.length > 0) &&
				currentBatchPromise === undefined
			) {
				console.debug("[batchFiles] Queue limit reached, batching operations");

				const allPromises = get(internalStore).currentlyProcessing.map((obj) => obj.promise);
				currentBatchPromise = Promise.allSettled(allPromises);

				currentBatchPromise.then(() => {
					console.debug("[batchFiles] Done with current batch, starting next...");

					update((currentStore) => {
						currentStore.currentlyProcessing.forEach((item) => {
							const finishedItem = item.item;
							finishedItem.status = finishedItem.status === "ERROR" ? "ERROR" : "COMPLETE";
							currentStore.finishedFiles.set(finishedItem.uuid, finishedItem);
							currentStore.allFiles.set(finishedItem.uuid, finishedItem);
						});
						currentStore.currentlyProcessing = [];
						return { ...currentStore };
					});
					currentBatchPromise = undefined;
					batchFiles();
				});
			} else if (currentBatchPromise) {
				console.debug("[batchFiles] Waiting for current batch to finish");
			} else {
				console.debug("[batchFiles] Done with all files");

				window.dispatchEvent(finishedBatchEvent);

				update((currentStore) => {
					currentStore.started = false;
					return { ...currentStore };
				});
			}
		}
	}

	function clear() {
		internalStore.set({
			pendingFiles: [],
			currentlyProcessing: [],
			finishedFiles: new Map(),
			allFiles: new Map(),
			started: false
		});
	}

	return { subscribe, queueItem, set, startQueue, clear, removeItemFromQueue, updateQueueLimit };
}

export const queueSystem = createQueueSystem();

export function getPageTitle(routes: Record<string, Route>, pageStore: typeof page): Writable<string> {
	// Page title is either defined in the Routes object/[authenticated]layout file, or is an HewSync Item.

	const pageTitleStore = writable("");
	const initial = get(pageStore);
	let currentPage = get(pageStore).url.pathname;
	getCurrentPageTitle(initial);

	function getCurrentPageTitle(currentStore: typeof initial) {
		const store = currentStore;
		currentPage = currentStore.url.pathname;

		try {
			if (routes[currentPage]) {
				pageTitleStore.set(routes[currentPage].label);
			} else if (store.params.id || store.params.organization) {
				// Possibly an HewSync Item, uses the same logic as breadcrumbs ([ui-components] PageHeader.svelte)

				const { slug, id, organization, type } = store.params;

				if (organization !== undefined) {
					const org = organization && UserOrganization.get({ organization: new UserOrganizationID(organization) });
					let item: ProjectInstance | UserGroup | UserOrganization | UserRole | UserMember | UserEntity;
					let prefix = slug ? slug : id ? "users" : organization ? "organization" : undefined;

					if (id) {
						if (!slug && !type) {
							item = ProjectInstance.get({ instance: new ProjectInstanceID(id), organization: org.organization });
							prefix = "synapse";
						} else if (slug === "groups") {
							item = UserGroup.get({ group: new UserGroupID(id), organization: org.organization });
						} else if (slug === "roles") {
							item = UserRole.get({ role: new UserRoleID(id), organization: org.organization });
						} else if (slug === "users") {
							item = UserMember.get({ account: new UserAccountID(id), organization: org.organization });
						} else if (type) {
							item = UserEntity.get({
								organization: new UserOrganizationID(organization),
								kind: type.toUpperCase(),
								entity: new UserEntityID(id)
							});
						}
					} else if (org) {
						item = org;
					}

					if (item.loading) {
						pageTitleStore.set("...");
						item.onUpdate.addCallback(() => {
							if (item.name) {
								pageTitleStore.set(prefix ? `${prefix} - ${item.name}` : `${item.name}`);
							}
						});
					} else {
						pageTitleStore.set(prefix ? `${prefix} - ${item.name}` : `${item.name}`);
					}
				} else {
					const { id, slug } = store.params;
					const root = store.url.pathname.split("/")[1];
					let item: UserOrganization | UserAccount | AdminGroup | AdminRole | AdminAccount;
					const prefix = slug ? slug : id ? "users" : undefined;

					if (id) {
						if (root === "organizations") {
							item = UserOrganization.get({ organization: new UserOrganizationID(organization) });
						} else if (root === "users") {
							item = UserAccount.get({ account: new UserAccountID(id) });
						} else if (slug === "groups") {
							item = AdminGroup.get({
								group: new AdminGroupID("0AdminAdminAdmin"),
								organization: new UserOrganizationID(organization)
							});
						} else if (slug === "roles") {
							item = AdminRole.get({
								organization: new UserOrganizationID(organization),
								role: new AdminRoleID("0AdminAdminAdmin")
							});
						} else if (slug === "admins") {
							item = AdminAccount.get({ account: new AdminAccountID(id) });
						}
					}

					if (item.loading) {
						pageTitleStore.set("...");
						item.onUpdate.addCallback(() => {
							if (item.name) {
								pageTitleStore.set(prefix ? `${prefix} - ${item.name}` : `${item.name}`);
							}
						});
					} else {
						pageTitleStore.set(prefix ? `${prefix} - ${item.name}` : `${item.name}`);
					}
				}
			} else {
				for (const route in routes) {
					if (routes[route].children) {
						const foundLabel = routes[route].label;
						if (foundLabel) {
							pageTitleStore.set(foundLabel);
							break;
						}
					}
				}
			}
		} catch (err) {
			console.warn("[getPageTitle]: ", err);
		}
	}

	pageStore.subscribe((change) => {
		getCurrentPageTitle(change);
	});

	return pageTitleStore;
}

export namespace TextValidatorUtils {
	//TODO: remove . restriction once we figure out a better way to detect synapse files in file preview panel
	export const reservedFolderCharacters = ["/", "\\", ".", "#"] as const;

	export const reservedFileCharacters = ["/", "\\", "#"] as const;

	export const emojiRegex = /[\p{Extended_Pictographic}]/u;

	export const characterLimit = 128;

	export function isSynapseFileNameValid(name: string): boolean {
		if (reservedFileCharacters.some((char) => name.includes(char))) {
			return false;
		}

		if (emojiRegex.test(name)) {
			return false;
		}

		return true;
	}

	export function isSynapseFolderNameValid(name: string): boolean {
		if (reservedFolderCharacters.some((char) => name.includes(char))) {
			return false;
		}

		if (emojiRegex.test(name)) {
			return false;
		}

		return true;
	}

	export function consistsOnlySpaces(name: string): boolean {
		return name.trim().length === 0;
	}
}

export const environment = {
	stage: undefined! as string,
	runMode: undefined as "live" | "local" | "emulated" | undefined
};

export function configureEnvironment(env: typeof environment) {
	Object.keys(env).forEach((key) => {
		if (env[key] === "") {
			env[key] = undefined;
		}
	});
	console.info("[configureEnvironment] Configuring environment...", env);
	Object.assign(environment, env);
}

/**
 * Get the origin route based on the current environment and RUN_MODE.
 * If not localhost, return the current window origin.
 * @returns {string} - The origin route based on the current environment
 */
export const getOriginRoute = () => {
	if (window.location.hostname === "localhost" && environment.runMode === "live") {
		let origins = {
			production: "hewmen.com",
			staging: "hewmen-staging.com",
			main: "hewmen-main.com"
		};
		let origin = origins[environment.stage] || `${environment.stage}.hewmen-internal.com`;
		return `https://${origin}`;
	} else {
		return window.location.origin;
	}
};

export const isInRunMode = (): "live" | "local" | "emulated" | boolean => {
	if (window.location.hostname === "localhost" && environment.runMode === "live") {
		return "live";
	} else if (window.location.hostname === "localhost" && environment.runMode === "local") {
		return "local";
	} else if (window.location.hostname === "localhost" && environment.runMode === "emulated") {
		return "emulated";
	}

	return false;
};

export function addToast(params: Parameters<typeof internalAddToast>[0]) {
	if (!params["message"]) {
		return;
	}

	if (window.heuiToastListener) {
		window.heuiToastListener.execute({ ...params });
	} else {
		console.warn("Event listener not found: ", window.heuiToastListener);
	}
}
