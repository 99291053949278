<script lang="ts">
	import { cva, type VariantProps } from "class-variance-authority";
	import { twMerge } from "tailwind-merge";
	import { DEFAULT_VARIANTS } from "../utils";

	const cardProps = cva(["card", "cursor-auto", "duration-300", "rounded-base", "shadow-lg", "transition-all"], {
		defaultVariants: {
			variant: "default"
		},
		variants: {
			variant: {
				...DEFAULT_VARIANTS,
				fifth: ["fifth"],
				fourth: ["fourth"],
				third: ["!bg-card-third-background"],
				secondary: ["!bg-card-secondary-background"]
			}
		}
	});
	type Props = VariantProps<typeof cardProps>;

	export let style: string | undefined = undefined;
	export let variant: Props["variant"] = undefined;

	let className: string | undefined = undefined;
	export { className as class };
</script>

<div
	class={twMerge(cardProps({ className, variant }))}
	style={style}
	on:contextmenu
	on:click
	role="button"
	tabindex="0"
>
	<slot name="header" />
	<slot />
</div>
