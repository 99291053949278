<script lang="ts">
	import { Button, Input, Message, Textarea, Icon } from "@hewmen-internal/heui";
	import Card from "../Card.svelte";
	import * as Sentry from "@sentry/sveltekit";
	import { fetchAuthSession } from "aws-amplify/auth";
	import { onMount } from "svelte";
	import { inDebugMode } from "../../utils";
	import type { Writable } from "svelte/store";

	export let event: ErrorEvent | PromiseRejectionEvent;
	export let store: Writable<string>;

	let body: string =
		event instanceof ErrorEvent
			? event.error.stack
			: event instanceof PromiseRejectionEvent
			? event.reason
			: "Unhandled Error";

	let err = event instanceof ErrorEvent ? event.error : event.reason;

	let email: string = "";
	let name: string = "";
	let errorId = "";

	onMount(async () => {
		const { tokens } = await fetchAuthSession();

		if (tokens) {
			name = (tokens.idToken.payload.name || "") as string;
			email = (tokens.idToken.payload.email || "") as string;
		}
		Sentry.setUser({ name, email });
		errorId = Sentry.captureException(err, { contexts: { sveltekit: { event } } });
	});
</script>

<article class="flex h-screen w-screen items-center justify-center bg-black/75">
	<div class="h-max w-max max-w-[75%] lg:max-w-[900px]">
		<Card class="bg-app-background text-app-dark noPadding flex h-full w-full flex-col">
			{#if inDebugMode()}
				<h1 class="border-b border-gray-400 p-4 text-3xl font-bold opacity-80">An Unrecoverable Error Occurred</h1>
				<Card class="bg-app-wrapper-background p-4">
					<Card class="bg-app-background">
						<p class="text-pretty max-h-[300px] overflow-y-auto p-4 opacity-75">{body}</p>
					</Card>
				</Card>
				<div class="text-app flex gap-2 border-t border-gray-400 p-4">
					<Button variant="warn">Refresh Page</Button>
					<Button
						variant="info"
						on:click={() => {
							window.location.href = "/";
						}}
					>
						Go Home
					</Button>
					<Button
						on:click={() => {
							$store = null;
						}}
					>
						Close
					</Button>
				</div>
			{:else}
				<h1 class="border-b border-gray-400 p-4 text-3xl font-bold opacity-80">
					Looks like we're having internal issues.
				</h1>
				<div class="flex flex-col gap-1 p-4">
					<p class="text-center opacity-75">A bug report has been automatically logged</p>
					{#if errorId}
						<div class="flex justify-center gap-1 p-1 text-center text-sm opacity-50">
							<p>Report ID:</p>
							<span>{errorId}</span>
						</div>
					{/if}
				</div>
				<div class="text-app flex w-full gap-2 border-t border-gray-400 p-4">
					<button
						class="rounded-base bg-message-info-background text-app hover:bg-message-info-background-hover px-3 py-2 leading-4 transition-all"
						on:click={(e) => {
							console.log(e);
							if (e.altKey && e.shiftKey) {
								$store = null;
							} else {
								window.location.reload();
							}
						}}
					>
						Refresh Page
					</button>
					<button
						class="rounded-base bg-message-warn-background text-app hover:bg-message-warn-background-hover px-3 py-2 leading-4 transition-all"
						on:click={(e) => {
							console.log(e);
							if (e.altKey && e.shiftKey) {
								$store = null;
							} else {
								window.location.href = "/";
							}
						}}
					>
						Go Home
					</button>
				</div>
			{/if}
		</Card>
	</div>
</article>
